@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "css/admin.css";
@import "css/portfolio.css";
@import "css/three-js-viewer.css";
@import "css/modal.css";
@import "css/about-me.css";
@import "css/footer.css";
@import "css/scrollbar.css";
:root {
    --background-color: #240D3D;
    --background-color-rgba: rgba(36, 13, 61, 0.5);
    --background-color-under-text: rgba(36, 13, 61, 0.9);
    --modal-background-color: rgb(9, 4, 14);
    --link-color: #a986cf;
    /*--link-color: #8B63B7;*/
    --link-color-disabled: #8B6FAA;
    --link-color-hover: #A47ECE;
    --header-color: #FFFFFF;
    --text-color: #e0e0e0;
    --text-shadow: rgba(46, 13, 61, 0.5);
    --header-height: 100px;
    --background-image: url('./img/bg.png');
    --font-open: 'Open Sans', sans-serif;
    --font-source: 'Source Sans Pro', sans-serif;
    --portfolio-header-height: 40px;
    --portfolio-modal-item-width:300px;
    --portfolio-modal-item-width-bigger:300px;
    --portfolio-modal-item-width-medium:600px;
    --portfolio-modal-item-width-medium-small:490px;
    --margin-medium:10px;
    --background-gradient-start: rgba(46, 13, 61, 0.5);
    --background-gradient-end: rgba(139, 99, 183, 0.5);
    --breakpoints-xs: 0;
    --breakpoints-sm: 576px;
    --breakpoints-md: 768px;
    --breakpoints-lg: 992px;
    --breakpoints-xl: 1200px;
    --breakpoints-xxl: 1400px;
    --viewWidthHalfModal: 40vw;
    --threeJSViewerIconSize:40px;
}
.disable-hover {
    pointer-events: none;
}
/*font-family: 'Noto Sans', sans-serif;*/
/*font-family: 'Source Sans Pro', sans-serif;*/
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0;
}

a {
    color: var(--link-color);
    text-decoration: none;
    display: inline-block;

}

a:hover {
    color: var(--text-color);
    text-decoration: none;
}

li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
}

body {
    background-image: var(--background-image);
}

label {
    color: var(--text-color);
    text-align: start;
}

.btn {
    border-radius: 0;
}

.btn-primary {
    background-color: var(--link-color);
    border-color: transparent;
    color: var(--background-color);
    font-weight: bold;
    border-radius: 0;
    margin: 10px;
}

.form-control {
    border-radius: 0px;
}

.form-control-file {
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid var(--link-color);
}

button:hover, .btn-primary:hover {
    background-color: var(--link-color-hover);
    border-color: var(--link-color-hover);
}

button:disabled {
    background-color: var(--link-color-disabled);
    border-color: var(--link-color-disabled);
}

.btn-primary.focus, .btn-primary:focus {
    background-color: var(--link-color);
    border-color: var(--link-color);
    box-shadow: 0 0 0 0.2rem transparent;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--link-color);
    border-color: var(--link-color);
    box-shadow: 0 0 0 0.2rem transparent;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    background-color: var(--link-color);
    border-color: var(--link-color);
    box-shadow: 0 0 0 0.2rem transparent;
}
.btn-Action {
    background-color: var(--link-color);
    border-color: transparent;
    color: var(--background-color);
    font-weight: bold;
    border-radius: 0;
}
.Button-Icon {
    max-width: 20px;
}

.Spinner-Custom {
    display: inline-flex;
    align-items: center;
    color: var(--text-color);
}

.Spinner-Custom-Message {
    text-transform: capitalize;
    margin: 10px;
    font-weight: bold;
}

.App {
    text-align: center;
    overflow-x: hidden;
}

.App-header {
    background-color: var(--background-color);
    height: 100px;
    width: 100%;
    max-width: 100vw;
    top: 0;
    left: 0;
    display: block;
    position: fixed;
    font-size: calc(10px + 2vmin);
    color: white;
    z-index: 10;
    font-family: var(--font-open);
    border-bottom: 1px solid white;
}

.App-link {
    color: var(--link-color);
}


.Navigation-Item {
    display: inline-block;
    font-size: large;
    margin-right: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    cursor: pointer;
}

.Navigation-List {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 80px;
    margin-right: 20px;
}

.Navigation-Link-Active {
    color: var(--header-color);
    font-weight: bold;
}

.Header-Title {
    display: inline-block;
    float: left;
    margin-left: 20px;
    margin-top: 20px;
    font-weight: bold;
}

.Content {
    color: white;
    font-family: var(--font-open);
    background: var(--background-color);
    width: 100%;
    margin: 0 auto;

}


.Resume-Section {
    min-height: 100vh;
}

.Carousel-Item {
    height: 100vh;
    object-fit: cover;
}

.Section-Header {
    background: var(--link-color);
    color: var(--background-color);
    font-size: xx-large;
    font-weight: normal;
    margin-bottom: 0;
}

.Section-Subheader {
    margin: 20px auto;
}

.Carousel-Section {
    position: relative;
    display: block;
    text-align: center;
    min-height: 100vh;
    background: black;
}

.Contact-Section {
    min-height: 100vh;
    text-align: center;

}

.Main-Welcome-Text {
    position: absolute;
    display: block;
    text-shadow: 1px 1px var(--text-shadow);
    bottom: 30%;
    width: 100%;
    font-size: xxx-large;
    text-transform: uppercase;
    z-index: 2;
}

.Exit-Marker {
    height: 1px;
    margin: 50px;
}

.Contact-Form {
    background: var(--text-color);
    width: 50%;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 50px auto;
    border-radius: 10px;
}

.Login-Page {
    margin: 80px;
    padding: 20px;
    color: var(--text-color);
    background: var(--background-color);
    border: 1px var(--link-color) solid;
}

.Login-Label {
    text-align: left;
}


/*bootstrap override*/
.Contact-Form {
    padding: 10px;
    border-radius: 0px;
    background-color: var(--background-color);
    border: 1px var(--link-color) solid;
}

.Expanded {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 102;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    background: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    visibility: visible;
    justify-content: center;
    align-items: center;
padding: 10px;
}
.Expanded img{
    max-height: 100%;
    max-width: 100%;
}


@media only screen and (max-width: 1500px), only screen and (max-device-width: 1500px) {
    .Header-Title {
        display: block;
        float: none;
        margin: 10px;
        margin-bottom: 0;
        font-size: 20px;
    }


    .App-Links {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Navigation-List {
        height: 50px;
        margin-right: 0;
    }

    .Main-Welcome-Text {
        font-size: 36px;
        line-height: 36px;
        bottom: 40%;
    }

    .Contact-Form {
        width: 80%;
    }


}

@media only screen and (max-width: 380px), only screen and (max-device-width: 380px) {
    .Navigation-Item {
        font-size: 14px;
    }

    .Main-Welcome-Text {
        font-size: 32px;
        line-height: 32px;
        bottom: 40%;
    }
}