.Admin-Form,
.Admin-Carousel-New {
    text-align: start;
    border-radius: 0px;
    background-color: var(--background-color);
    border: 1px var(--link-color) solid;
}

.Admin-Form-Header {
    color: var(--text-color);
    padding: 10px;
    display: inline-block;
}

.Admin-Media-Header {
    display: flex;
    justify-content: space-between;
}

.Admin-Portfolio-Add {

    min-height: 100vh;
}

.Admin-Action-Buttons-Right {
    text-align: end;
}

.Admin-Feature-Image-Wrapper {
    text-align: center;
}

.Admin-Card {
    border-radius: 0;
    text-align: start;
    border-color: var(--background-color);
}

.Admin-Card-Header {
    color: var(--background-color);
    background-color: var(--link-color);
    font-weight: bold;
}
.Admin-Card-Header button {
    color: var(--background-color);
    background-color: var(--link-color);
    font-weight: bold;
}
.Admin-Card-Header button:not(.collapsed) {
    color: var(--text-color);
    border: 2px solid var(--background-color);
    border-radius: 10px;
    background-color: #6C429BFF;
    font-weight: bold;
    outline: none;
    box-shadow:none;
}
.Admin-Card-Body {
    background: var(--background-color);
    color: var(--text-color);
}

.Admin-About-Me-Image {
    max-width: 400px;
}

.Admin-Portfolio-Items-Wrapper {
    display: flex;
    flex-wrap: wrap;
}

.Admin-Portfolio-Basic {
    margin: 10px;
    box-shadow: 0 0 3px 3px var(--link-color);
    position: relative;
    width: 300px;
    display: inline-flex;
    background: transparent;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Admin-Portfolio-Basic-Title {
    font-weight: bold;
    background: var(--background-color-under-text);
    position: absolute;
    padding: 10px;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Admin-Portfolio-Basic-Spinner-Wrapper {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--background-color-under-text);
    padding: 10px;
}

.Admin-Portfolio-Basic-Buttons-Wrapper {
    position: absolute;
    top: 0;
    right: 0;
}

.Admin-Portfolio-Basic-Button,
.Admin-Carousel-Basic-Button {
    margin-left: 5px;
    margin-top: 0;
}

.Admin-Carousel-Media-Wrapper {
    text-align: center;
}

.Admin-Carousel-Video {
    width: 400px;
}

.Admin-Slide-Wrapper {
    margin: 10px;
    box-shadow: 0 0 3px 3px var(--link-color);
    position: relative;
    width: 500px;
    height: 294px;
    text-align: center;
    display: inline-flex;
    background: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Admin-Slide-Canvas {
    width: 500px;

}

.Admin-Slide-Title-Wrapper {
    top: 50%;
    left: 50%;
    position: absolute;
    padding: 10px;
    background: var(--background-color-under-text);
    transform: translate(-50%, -30%);
}

.Admin-Slide-Basic-Title {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}

.Admin-Slide-Basic-Subtitle {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    font-size: x-small;
}

.Admin-Slide-Basic-Spinner-Wrapper {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--background-color-under-text);
    padding: 10px;
}

.Admin-Slide-Basic-Buttons-Wrapper {
    position: absolute;
    top: 0;
    right: 0;
}

.Admin-Slide-Basic-Button {
    margin-left: 5px;
    margin-top: 0;
}


.Admin-Portfolio-Feature-Image {
    max-width: 500px;
}

.Admin-Three-Component-Wrapper {
    text-align: center;
}

.Admin-Three-Component {
    max-height: 220px;
}

.Admin-Three-Delete-Button {
    margin: 0;
}

.Admin-Header-Media {
    color: var(--text-color);
    width: 100%;
    text-align: center;
    background: var(--background-gradient-end);
}


.Admin-Youtube-Video {
    max-width: 500px;
    width: 500px;
    min-height: 280px;
}

.Admin-Media {
    border: 1px solid var(--link-color);
    padding: 10px;
    margin: 10px 0;
    text-align: center;
}

.Admin-Media-Add {
    border: 1px solid var(--link-color);
    padding: 10px;
}

.Admin-Media-Add-Title,
.Admin-Media-Title {
    color: var(--text-color);
}

.Admin-Media-Title {
    margin: 10px 0;
}
.Admin-Update-Message {
    color: var(--text-color);
    text-align: center;
}
.Admin-Accordion{
    background: var(--background-color);
}