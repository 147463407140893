* {
    scrollbar-width: thin;
    scrollbar-color: var(--link-color) var(--background-color);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: var(--background-color);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--link-color);
    border-radius: 0px;

}

*::-webkit-scrollbar-button:single-button {
    background-color: var(--link-color);
}

*::-webkit-scrollbar-corner {
    background-color: var(--link-color);
}
