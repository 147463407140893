.Portfolio-Feature-Item {
    max-width: calc(100% / 3);
    display: inline-block;
    aspect-ratio : 1 / 1;
}

.Portfolio-Feature-Item img {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.Portfolio-Item-Overlay {
    position: absolute;
    transition: all 0.3s ease;
    opacity: 0;
    display: flex;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    background: linear-gradient(var(--background-gradient-start), var(--background-gradient-end));
}

.Portfolio-Feature-Item:hover .Portfolio-Item-Overlay {
    opacity: 1;
}

.Portfolio-Expandable-Image{
    max-width: 100%;
}
.Portfolio-Item-Intro {
    display: block;
    width: 80%;
    margin: 20px auto;
    min-height: 250px;
}

.Portfolio-Item-Intro-Image {
    width: 100%;
    max-height: 250px;
    float: left;
}

.Portfolio-Item-Description {
    color: var(--text-color);
    text-align: start;
    font-size: large;
}

.Portfolio-Item-Overlay-Fade {
    height: 100%;
    width: 100%;
    top: 0;
    pointer-events: none;
    left: 0;
    background: linear-gradient(var(--background-gradient-start), var(--background-gradient-end));

}

.Portfolio-Item-Overlay-Title {
    font-weight: 600;
    border-bottom: 1px solid white;
    padding: 1px 20px;
}
.Portfolio-Featured-Grid{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
}
.Portfolio-Featured-Grid:first-of-type{
    flex-direction: column;
    margin-bottom: 10px;
}
.Portfolio-Featured-Grid-Item{
    max-width: 80%;
}
.Portfolio-Featured-Image{
    width: 50%;
}
.Portfolio-Item-Text{
    text-align: justify;
}
.Portfolio-Iframe {
    overflow: hidden;
}

.Portfolio-Gallery {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    width: calc((var(--portfolio-modal-item-width)) + (2 * var(--margin-medium)));
}

.Portfolio-Image {
    width: 400px;

}

.Portfolio-Title {
    position: fixed;
    width: 80vw;
    margin-top: -60px;
    z-index: 60;
    height: var(--portfolio-header-height);
}

.Portfolio-Image-Wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}
.Portfolio-Image-Wrapper-Image-Cover {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    width: var(--portfolio-modal-item-width);
    background-position: center;
    gap: var(--margin-medium);
}

.Portfolio-Image-Feature-Background {
    background: linear-gradient(var(--background-color-rgba), var(--background-gradient-end));
}

.Portfolio-Image-Background {
    height: 400px;
    width: 400px;
    z-index: -1;
    position: absolute;
    top: 0;
    filter: blur(8px) brightness(80%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Portfolio-Image-Gallery-Primary {
    width: 100%;
    max-width: 1000px;
    margin: 10px;

}
.Portfolio-Video-Gallery {
    width: var(--portfolio-modal-item-width);
    height: 200px;
}
.Portfolio-Video-Gallery:focus {
    outline: none;
}

.Portfolio-Video {
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1000px;
}

.Portfolio-Video:focus {
    outline: none;
}
@media only screen and (min-width: 412px), only screen and (min-device-width: 412px) {

}
@media only screen and (max-width: 1500px), only screen and (max-device-width: 1500px) {
    .Portfolio-Item-Intro-Image {
        width: 60%;
        margin: 0px 10px 10px 0;
    }
    .Portfolio-Feature-Item {
        max-width: calc(100% / 2);
        display: inline-block;

    }
    .Portfolio-Video-Gallery {
        width: var(--portfolio-modal-item-width);
        height: 200px;

    }
}

@media only screen and (min-width: 768px), only screen and (min-device-width: 768px) {

    .Portfolio-Image-Wrapper-Image-Cover{
        width: var(--portfolio-modal-item-width-medium);
        height: 400px;
    }
    .Portfolio-Video-Gallery {
        width: var(--portfolio-modal-item-width-medium);
        height: 400px;
    }
    .Portfolio-Gallery {

        width: calc((var(--portfolio-modal-item-width-medium)) + (var(--margin-medium)*2));

    }
}

@media only screen and (min-width: 992px), only screen and (min-device-width: 992px) {
    .Portfolio-Featured-Grid{
        flex-direction: row;
    }
    .Portfolio-Featured-Grid:first-of-type{
        margin-bottom: 0;
        flex-direction: row;
    }
    .Portfolio-Featured-Grid-Item{
        max-width: 50%;
    }
    .Portfolio-Image-Gallery {
        max-width: 800px;
        margin: 10px;
        height: 400px;
    }
    .Portfolio-Item-Intro-Image {
        width: 30%;
    }
}
@media only screen and (min-width: 1200px), only screen and (min-device-width: 1200px) {
    .Portfolio-Image-Wrapper-Image-Cover{
        width: var(--portfolio-modal-item-width-medium-small);
    }

    .Portfolio-Gallery {
        width: calc((var(--portfolio-modal-item-width-medium-small)*2) + (var(--margin-medium)*3));}
}
@media only screen and (min-width: 1560px), only screen and (min-device-width: 1560px) {
    .Portfolio-Image-Wrapper-Image-Cover{
        width: var(--portfolio-modal-item-width-medium);
    }

    .Portfolio-Gallery {
        width: calc((var(--portfolio-modal-item-width-medium)*2) + (var(--margin-medium)*3));}
}

@media only screen and (min-width: 1900px), only screen and (min-device-width: 1900px) {
    .Portfolio-Feature-Item {
        max-width: calc(100% / 3);
        display: inline-block;

    }

    .Portfolio-Item-Intro-Image {
        width: 400px;
        margin-right: 10px;
        /*margin-top: 10px;*/
    }

    .Portfolio-Item-Description {
        font-size: x-large;
        margin-top: 0;
    }
    .Portfolio-Item-Primary-Media {
    }
    /*.Portfolio-Video-Gallery {*/
    /*    width: 80%;*/
    /*    height: 600px;*/
    /*    margin: 10px;*/
    /*    object-fit: cover;*/
    /*}*/
}

@media only screen and (min-width: 2100px), only screen and (min-device-width: 2100px) {
    .Portfolio-Feature-Item {
        max-width: calc(100% / 3);
        display: inline-block;

    }
    /*.Portfolio-Video-Gallery {*/
    /*    width: 80%;*/
    /*    height: 400px;*/
    /*    margin: 10px;*/
    /*}*/
}



.Portfolio-Page-Detailed{

}
.Portfolio-Item-Text-ExtraDescription{
    margin: 20px 0;
}
.Portfolio-Gallery-Row{
    /*gap: 10px;*/
    /*grid-gap: 10px;*/
}
.Portfolio-Image-Gallery-Item{
    aspect-ratio: 3/2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.Portfolio-Video-Gallery-Item{
    aspect-ratio: 3/2;
}
.Carrousel-Image{
    width: 100%;
    aspect-ratio: 3/2;
}

.thumb{
    pointer-events: none;
}
.video-thumb{
    display: flex;
    justify-content: center;
    align-content: center;
    background: #240D3D;
    max-width: inherit;
    aspect-ratio: 3/2;
    align-items: center;
}
.video-thumb-icon{
    aspect-ratio: 3/2;
    background: darkred;
    border-radius: 10px;
    text-align: center;
    line-height: 1.5rem;
    padding: 5px 15px;
}
.carrousel-item-3d-thumb{
    position: relative;
    overflow: hidden;
    aspect-ratio: 3/2;
    background: #240D3D;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carrousel-item-3d-thumb img{
    width: 60%;
    aspect-ratio: 1/1;
}
.Carrousel-Gallery-Image-Description{
    padding:10px;
    background: var(--background-color-under-text);
}