
.Modal {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    z-index: 101;
    width: 100vh;
    height: 100vh;
    max-width: 100vw;
    background: #ececec;
}

.Modal-Visible {
    display: block;
    position: fixed;
    z-index: 101;
    visibility: visible;
    width: 100%;
    animation: showModal 0.5s;
    animation-fill-mode: backwards;
    background: var(--modal-background-color);
    overflow-y: auto;
}

.Modal-Hidden {
    top: -100vh;
    position: fixed;
    z-index: 101;
    width: 100%;
    background: var(--modal-background-color);
    animation: hideModal 0.5s;
    animation-fill-mode: backwards;
    visibility: hidden;
    display: block;

}

@keyframes hideModal {
    from {
        top: 0;
        visibility: visible
    }
    to {
        top: -100vh;
        visibility: hidden
    }
}

@keyframes showModal {
    from {
        top: -100vh;
    }
    to {
        top: 0;
    }
}

.Modal-Content-old {
    width: 80vw;
    height: 80vh;
    margin: 0 auto;
    margin-top: var(--header-height);
    background: var(--background-color);
    overflow-y: auto;
    box-shadow: 0 0 3px 3px var(--text-color);


}
.Modal-Content {
    margin: 0 auto;
    background: var(--modal-background-color);
    backdrop-filter: blur(20px);

}
.Modal-Header{
    display: flex;
flex-direction: row-reverse;
}
.Modal-Navigation-Button{
    margin: 20px;
    font-size: xx-large;
    cursor:pointer;
}
.Section-Portfolio-Modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 80%;
    margin-top: 100px;
    background: var(--background-color);
}

.Section-Portfolio-Modal-Body {
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--background-color);
}

.Portfolio-Modal-Wrapper {
    margin-top: 60px;
}

.Portfolio-Modal-Inner {
    margin-top: var(--portfolio-header-height);
}

div[title~=Modal-Alert-Small] {
    display: flex;
    align-items: center;
    height: 100vh;
}

div[title~=Modal-Alert-Small] .modal-content {
    background: var(--background-color);
    border-radius: 0;
    color: var(--text-color);
    box-shadow: 0 0 3px 3px var(--text-color);
}

div[title~=Modal-Alert-Small] .modal-content .modal-header {
    border-bottom: 0px solid transparent;
    align-items: center;
    justify-content: center;
    background: var(--link-color);
    color: var(--background-color);

}

div[title~=Modal-Alert-Small] .modal-content .modal-header .h4 {
    font-size: xx-large;
    font-weight: bold;
}

div[title~=Modal-Alert-Small] .modal-content .modal-footer {
    border-top: 0px solid transparent;
}
