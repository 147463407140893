.About-Me-Image {
    display: inline-block;
max-width: 100%;

}

.About-Me-Text {
    display: inline-block;
    vertical-align: middle;

    padding: 20px 0px 20px 0px;
    color: var(--text-color);
    font-size: large;
    text-align: start;

}

.About-Me-Section {
    min-height: 50vh;

}

@media only screen and (min-width: 1900px), only screen and (min-device-width: 1900px) {

    .About-Me-Image {
        display: inline-block;
        max-width: 380px;
        padding: 20px;
        float: left;
    }

}
@media only screen and (min-width: 1200px), only screen and (min-device-width: 1200px) {
    .About-Me-Image, .About-Me-Text {

        margin: 20px auto;
        padding: 0;
    }




    .About-Me-Text {

        margin-top: 0;
    }
    .About-Me-Image {
        display: inline-block;

        max-width: 380px;
    }

    .About-Me-Text {
        display: inline-block;
        vertical-align: middle;

        padding: 0px 0px 0px 20px;
        color: var(--text-color);
        font-size: large;
        text-align: start;

    }
}
