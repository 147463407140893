.Three-Model-Viewer {
    position: relative;
}

.Three-Object {
    width: var(--portfolio-modal-item-width);
    aspect-ratio: 3/2;
    margin: 0 auto;
}

.Three-Object canvas:focus {
    outline: none;
}

.Three-Controls {
    right: calc((100% - 298px) / 2);
    position: absolute;
    top: 40px;
    width: 40px;
    height: calc(100% - 40px);
    margin: 0 auto;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;

}

.Three-Controls-Hidden {
    visibility: hidden;
}

.Three-Controls-Visible {
    visibility: visible;
}

.Three-Controls-Icon {
    right: calc((100% - 300px) / 2);
    /*above is (100%parent minus parent hardcoded width) divided on 2 to find edge of parent */
    position: absolute;
    top: 0px;
    width: var(--threeJSViewerIconSize);
    height: var(--threeJSViewerIconSize);

}

.Three-Controls-Icon:focus {
    outline: none;
}

.Three-Controls-Icon-Down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--link-color);
    transition: all 0.2s linear;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Three-Controls-Icon-Up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--link-color);
    transition: all 0.2s linear;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Three-Control-Slider {
    border: none;
    outline: none;
    background: var(--link-color);
    padding: 3px;
    width: 33%;
}

input[type=range][orient=vertical] {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    width: 8px;
    height: 175px;
    padding: 0 5px;
}

.Three-Control-Item {
    border: none;
    outline: none;
    background: black;
    padding: 3px;
    width: 40px;
    margin: 5px;
}

.Three-Control-Item:hover {
    background: var(--background-color);
}

.Three-Control-Item-Active {
    background: var(--background-color);
}

.Three-Control-Item-Non-Active {
    background: black;
}

.Three-Control-Item img {
    width: 30px;
    height: 30px;
}

.Three-Control-Item:focus {
    background: var(--link-color);
    outline: none;
}

.Three-Control-Item-Label {
    border: none;
    outline: none;
    background: black;
    width: 40px;
    margin: 0;
}

.Three-Control-Item-Label img {
    width: 30px;
    height: 30px;
}

@media only screen and (min-width: 768px), only screen and (min-device-width: 768px) {

    .Three-Object {
        width: var(--portfolio-modal-item-width-medium);
       aspect-ratio: 3/2;
    }

    .Three-Controls {
        right: calc((100% - 598px) / 2);
    }

    .Three-Controls-Icon {
        right: calc((100% - 600px) / 2);
    }
}

.Three-Item-Gallery {
    width: 100%;

}

.thumb-3d-overlay {
    z-index: 150;
    width: 100%;
    height: 100%;
    color: var(--link-color);
    font-size: 32px;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    align-items: center;
    justify-content: center;
}

.carrousel-th-3d {
    position: relative;
    max-width: 90px;
    max-height: 60px;
    overflow: hidden;
    aspect-ratio: 3/2;
}

.ThreeD-thumb {
    max-width: 100%;
    width: 80px;
    max-height: 60px;
}

.no-visible {
    display: none;
    pointer-events: none;
}