.Footer {
    background: var(--link-color);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.Footer-Button {
    margin: 10px;
}

.Footer-Text {
    margin: 10px;
}

.Footer-Email-Link {
    cursor: pointer;
    color: var(--text-color);
    text-decoration: none;
    text-decoration-color: var(--text-color);;
}

.Footer-Email-Link:before {
    content: ' \2709';
    margin: 0 5px;
}